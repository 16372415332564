import "../styles/Info.scss";

import React, { useContext, useEffect, useState, createRef } from "react";

import Footer from "../components/Footer.jsx";
import InfoBlock from "../components/InfoBlock.jsx";
import LocaleContext from "../context/LocaleContext.jsx";
import Navbar from "../components/Navbar.jsx";
import { graphql } from "gatsby";
import { motion } from "framer-motion";

function Info({ data }) {
  const [locale] = useContext(LocaleContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  );
  const [mouseY, setMouseY] = useState(0);
  const canvasRef = createRef(null);

  useEffect(() => {
    const updateMousePosition = (e) => {
      // canvasRef.onMouseMove((f) => {
      // console.log({ f });
      setMouseY(e.pageY);
      // });
    };

    if (typeof window !== "undefined") {
      document.addEventListener("mousemove", updateMousePosition);
    }
    return () => {
      document.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  //DÉTECTE ET ENREGISTRE LA LARGEUR DE LA FENÊTRE
  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
    return () =>
      typeof window !== "undefined" &&
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);

  const dataEvents = data.allContentfulPageEvenements.edges;
  const dataEventsLocale =
    locale === "fr"
      ? dataEvents[0].node.listeEvenements
      : dataEvents[1].node.listeEvenements;

  const eventsList = dataEventsLocale.map((event, i) => {
    return (
      <InfoBlock
        date={event.date}
        description={event.description}
        onhover={() => setCurrentImageIndex(i)}
        windowWidth={windowWidth}
        key={i}
      />
    );
  });

  return (
    <div className="info-container" id="mouseMoveId" ref={canvasRef}>
      <Navbar currentPage="info" />
      {dataEventsLocale[currentImageIndex].image !== null && (
        <motion.img
          className="cursor-image"
          animate={{
            top: mouseY !== null ? mouseY - 150 : "calc( 50vh - 150px )",
          }}
          src={dataEventsLocale[currentImageIndex].image.file.url}
          alt="eventImage"
        />
      )}
      <div className="text-container">
        <div className="text-inner">{eventsList}</div>
      </div>
      <Footer />
      <script type="text/javascript"></script>
    </div>
  );
}

export const query = graphql`
  query InfoQuery {
    allContentfulPageEvenements {
      edges {
        node {
          listeEvenements {
            node_locale
            date
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Info;
