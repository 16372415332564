import "../styles/InfoBlock.scss";

import React, { useState } from "react";

import { motion } from "framer-motion";

function InfoBlock({ date, description, onhover, windowWidth }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      onMouseEnter={() => {
        if (windowWidth > 900) {
          onhover();
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (windowWidth > 900) {
          setIsHovered(false);
        }
      }}
      onClick={() => {
        if (windowWidth < 900) {
          setIsHovered(!isHovered);
        }
      }}
      className="info-block"
      role="row"
      tabIndex={0}
    >
      <motion.div
        animate={{ letterSpacing: isHovered ? "1px" : "12px" }}
        className="info-b-date"
      >
        {date}
      </motion.div>
      <motion.div
        animate={{
          opacity: !isHovered && windowWidth > 900 ? 0 : 1,
          transform:
            !isHovered && windowWidth > 900
              ? "translateX(120%)"
              : "translateX(0px)",
        }}
        className="info-b-text"
      >
        {description}
      </motion.div>
    </motion.div>
  );
}

export default InfoBlock;
